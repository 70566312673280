import React from 'react'
import tw, { styled } from 'twin.macro'
import Card from './Card'

// class="
const Message = styled.div`
  ${tw`font-sans text-black font-medium mx-0 w-full p-4 bg-gray-100 rounded-lg`}
`

export default ({ data }) => {
  return (
    <Card icon="wllnu" action="posted on wllnu.com" date={data.Created}>
      <Message>{data.message}</Message>
    </Card>
  )
}
