import React from 'react'
import tw, { styled } from 'twin.macro'
import TextLoop from 'react-text-loop'

const TextTitle = styled.h1`
  ${tw` text-white leading-none text-5xl block font-sans font-extrabold`}
  @media (min-width: 800px) {
    font-size: 4.5rem;
  }
  @media (min-width: 1350px) {
    font-size: 6rem;
  }
`
// class="
const TextSubtitle = styled.div`
  ${tw` text-black leading-snug text-3xl block font-serif font-bold w-full text-center`}
  @media (min-width: 800px) {
    font-size: 2.2rem;
  }
  @media (min-width: 1350px) {
    font-size: 3rem;
  }
`
const Background = styled.div`
  ${tw`bg-grey absolute top-0 left-0 w-full`}
  z-index: -10;
  height: 95vh;
`
// class="
const BackgroundYellow = styled.div`
  ${tw`bg-yellow relative w-1/6`}
  height: 2rem;
  top: -2.2rem;
  z-index: -2;
`
const StyledLoop = styled(TextLoop)`
  > div > div {
    width: 40vw !important;
  }
`
// class="
const HeroWrapper = styled.div`
  ${tw`relative mb-16`}/* left: -20%; */
`
const HeroSection = styled.div`
  ${tw`w-full flex flex-col items-center`} /* left: -20%; */
  height: 90vh;
`

const Info = styled.span`
  ${tw`relative font-serif text-xl text-blue`}
  line-height: 0;
`
const InfoWrapper = styled.div`
  ${tw`inline`}
`

export default () => (
  <HeroSection>
    <Background />
    <HeroWrapper>
      <TextTitle>FULLSTACK</TextTitle>
      <TextTitle>
        JAVASCRIPT
        {/* <InfoWrapper>
          <Info>...this site is under construction</Info>
          <Info>...please come back later</Info>
        </InfoWrapper> */}
      </TextTitle>
      <TextTitle>DEVELOPER</TextTitle>
    </HeroWrapper>
    <TextSubtitle>Hi, my name is Willi</TextSubtitle>
    <TextSubtitle>I turn coffee into</TextSubtitle>
    <StyledLoop fade="false" intervall="4500" delay="1000">
      <TextSubtitle>code</TextSubtitle>
      <TextSubtitle>git commits</TextSubtitle>
      <TextSubtitle>react components</TextSubtitle>
      <TextSubtitle>node scripts</TextSubtitle>
      <TextSubtitle>database queries</TextSubtitle>
    </StyledLoop>
    <BackgroundYellow></BackgroundYellow>
  </HeroSection>
)
