import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BlogCard, TwitterCard, BookmarkCard, GithubCard } from './cards/index'
import tw, { styled } from 'twin.macro'

const SelectCard = (props) => {
  const source = props.source
  if (source === 'Twitter') {
    return <TwitterCard data={props.data}></TwitterCard>
  } else if (source === 'Bookmarks') {
    return <BookmarkCard data={props.data}></BookmarkCard>
  } else if (source === 'Github') {
    return <GithubCard data={props.data}></GithubCard>
  } else if (source === 'wllnu.com (slack)') {
    return <BlogCard data={props.data}></BlogCard>
  }
}

export default () => {
  const allAirtableData = useStaticQuery(
    graphql`
      query {
        allAirtable(sort: { fields: data___Created, order: DESC }) {
          nodes {
            data {
              action
              commit
              full
              link
              message
              repo
              tag
              Created(fromNow: true)
            }
            recordId
            table
          }
          pageInfo {
            itemCount
            totalCount
          }
        }
      }
    `
  )
  const data = allAirtableData.allAirtable.nodes

  // class="
  const FeedWrapper = styled.div`
    ${tw`flex flex-col items-center sm:w-11/12 md:w-9/12 lg:w-3/6 m-auto  overflow-y-scroll overflow-x-hidden  mt-8 mb-6 pt-3`}
    max-height: 90vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `
  // class="
  const H1 = styled.h1`
    ${tw`font-serif font-bold mx-auto text-center`}
  `
  const H5 = styled.h5`
    ${tw`font-sans mx-auto text-center`}
  `

  return (
    <>
      <H1>My Newsfeed</H1>
      <H5>(overview of my recent activities on the internet)</H5>
      <FeedWrapper>
        {/* <div> */}
        {data &&
          data.map((card) => (
            <SelectCard
              source={card.table}
              key={card}
              data={card.data}
            ></SelectCard>
          ))}
        {/* </div> */}
      </FeedWrapper>
    </>
  )
}
