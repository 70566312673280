import React from 'react'
import tw, { styled } from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons'

// class="
const CardBkg = styled.div`
  ${tw`shadow-xl w-11/12 h-auto rounded-xl  border-red-700 bg-white mb-6 mx-0`}
`
const CardHeader = styled.div`
  ${tw`text-xs md:text-base p-2 w-full flex flex-col md:flex-row justify-between mx-2`}
`
const CardTextBody = styled.div`
  ${tw`py-1 px-0 md:px-4 w-full`}
  margin-left: -2rem;
  width: 110%;
  @media (min-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`
const CardContent = styled.div`
  ${tw`p-4 w-full flex mx-0 relative flex-shrink`}
`
const CardIcon = styled.div`
  ${tw`p-1`}
`
const CardText = styled.div`
  ${tw`mx-0 w-full`}
`
// class="
const CardWilli = styled.span`
  ${tw`text-base md:text-lg font-sans font-black text-black mr-2`}
  color: ${(props) => (props.color ? props.color : '#01001A')}
`
const CardWhat = styled.span`
  ${tw`inline-block md:inline text-xs md:text-base p-1 px-3 font-sans font-bold text-black ml-2 bg-grey-500 rounded-3xl`}
`
const CardTime = styled.span`
  ${tw`text-xs font-sans text-gray-600 object-right`}
`
const Avatar = styled.img`
  /* ${tw`p-1`} */
  width: 35px;
  border-radius: 100%;
`

const SelectIcon = (props) => {
  const source = props.icon
  if (source === 'twitter') {
    return (
      <FontAwesomeIcon
        style={{ color: '#1DA1F2' }}
        icon={faTwitter}
        size="2x"
      />
    )
  } else if (source === 'bookmarks') {
    return (
      <FontAwesomeIcon
        style={{ color: '#0D00FF' }}
        icon={faBookmark}
        size="2x"
      />
    )
  } else if (source === 'github') {
    return <FontAwesomeIcon icon={faGithub} size="2x" />
  } else if (source === 'wllnu') {
    return (
      <Avatar src="https://avatars1.githubusercontent.com/u/13342175?s=460&u=6998eaafebf5f98b91e8d0a3d308540556bfbb39&v=4"></Avatar>
    )
  }
}
export default (props) => {
  return (
    <CardBkg>
      <CardContent>
        <CardIcon>
          <SelectIcon icon={props.icon} />
        </CardIcon>
        <CardText>
          <CardHeader>
            <span>
              <CardWilli color={props.color}>willi</CardWilli>
              <span>{props.action}</span>
              {props.what && <CardWhat>{props.what}</CardWhat>}
            </span>
            <CardTime>{props.date}</CardTime>
          </CardHeader>
          <CardTextBody>{props.children}</CardTextBody>
        </CardText>
      </CardContent>
    </CardBkg>
  )
}
