import { indexOf } from 'lodash'
import React from 'react'
import tw, { styled } from 'twin.macro'
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Card from './Card'
import { trim } from 'lodash'

const Message = styled.div`
  ${tw`font-sans text-black font-medium mx-0 w-full p-4 bg-gray-100 rounded-lg`}
`

export default ({ data }) => {
  const trimmedUrl = (url) => {
    return url.substring(0, 35)
  }
  return (
    <Card
      icon="bookmarks"
      action="bookmarked a website"
      color="#0D00FF"
      what={`#${data.tag}`}
      date={data.Created}
    >
      <Message>
        <FontAwesomeIcon
          //   style={{ color: '#1DA1F2' }}
          icon={faWindowRestore}
          size="1x"
        />
        {'    '}
        <a href={data.link} target="_blank">
          {trimmedUrl(data.link)}...
        </a>
      </Message>
    </Card>
  )
}
