import React from 'react'
import tw, { styled } from 'twin.macro'
import Card from './Card'

export default ({ data }) => {
  if (data.action === 'tweet') {
    return (
      <Card icon="twitter" action="tweeted" color="#1DA1F2" date={data.Created}>
        <blockquote class="twitter-tweet">
          <a href={data.link}></a>
        </blockquote>
      </Card>
    )
  } else {
    return (
      <Card
        icon="twitter"
        action="liked a tweet"
        color="#1DA1F2"
        date={data.Created}
      >
        <blockquote class="twitter-tweet">
          <a href={data.link}></a>
        </blockquote>
      </Card>
    )
  }
}
