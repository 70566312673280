import React from 'react'
import tw, { styled } from 'twin.macro'
import Card from './Card'

// class="
const Message = styled.div`
  ${tw`font-sans text-black font-medium mx-0 w-full p-4 bg-gray-100 rounded-lg`}
`
const Commit = styled.span`
  ${tw`font-sans text-blue text-sm mr-2 `}
`

export default ({ data }) => {
  const getRepoName = (url) => {
    const start = 'https://github.com/willi-nuechterlein/'
    const repoName = url.slice(start.length, url.indexOf('/commit'))
    return repoName
  }
  const getCommit = (url) => {
    const start = url.indexOf('/commit/') + 8
    const repoName = url.slice(start, start + 7)
    return repoName
  }
  return (
    <Card
      icon="github"
      action="commited to"
      what={getRepoName(data.commit)}
      date={data.Created}
    >
      <Message>
        <Commit>{getCommit(data.commit)}</Commit>
        {data.message}
      </Message>
    </Card>
  )
}
